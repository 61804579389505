import React, {FC, PropsWithChildren, useCallback} from "react";
import {
  ABORT_PAYMENT_TIME_SEC,
  API_URL,
  EBonusSystemType,
  EPaymentMethods,
  EPaymentSteps,
  ERROR_CODES,
  ERRORS
} from "types/constants";
import {HttpError} from "@refinedev/core";
import {useBonusCard, useSendEmail} from "./hooks";
import {makeSound, saveYBadgeLastPaymentUnixTime} from "libs/utils";
import {useCartContext, useCatalogData, useGlobalSettings} from "context";
import urlJoin from "url-join";
import {formatterPaymentBody, formatterYPaymentBody} from "./utils";
import {useGetMaxBonusesValue} from '../libs/hooks';


export interface PaymentState {
  displayPayment?: boolean,
  confirmClosedPaymentModal?: boolean,
  paymentStep: EPaymentSteps,
  bonusCard?: any,
  bonusCardNumber: string,
  spentBonusCount: number,
  isCheckingBonusCardNumber?: boolean,
  isErrorBonusCard?: boolean,
  selectedPaymentMethod: EPaymentMethods | EBonusSystemType | null,
  sendingEmailError?: any,
  paymentError: ERRORS | null,
  paymentErrorMessage: string,
  isPaymentLoading?: boolean,
  isPaymentCardLoading?: boolean,
  isPaymentWaiting?: boolean,
  isPaymentProcess?: boolean,
  isSuccessPayment?: boolean,
  orderData?: any
  collectEmailForReceipt?: boolean
}


interface PaymentMethodsContext extends PaymentState {
  onClearPaymentContext: () => void
  onStartPaymentAgain: () => void
  onMakeYandexPayment: (paymentToken?: string) => void
  onChangePaymentStep: (step: EPaymentSteps) => void
  onChangeBonusCardNumber: (value: string) => void
  onChangeSpentBonusCount: (value: number) => void
  onSelectPaymentMethod: (method: EPaymentMethods | EBonusSystemType | null) => void
  onValidateBonusCard: (value?: string) => any
  onClearBonusCard: () => void
  onCloseDisplayPayment: () => void
  onOpenDisplayPayment: (flag: boolean) => void
  onConfirmClosedPaymentModal: () => void
  onCancelClosedPaymentModal: () => void
  onMakePayment: () => void
  onSendEmail: (email: string, orderId: string) => void
}

const initialStorePaymentState = {
  isPaymentLoading: false,
  isPaymentCardLoading: false,
  isPaymentWaiting: false,
  isSuccessPayment: false,
  displayPayment: false,
  confirmClosedPaymentModal: false,
  paymentStep: EPaymentSteps.PAYMENT_METHODS,
  paymentError: null,
  paymentErrorMessage: '',
  selectedPaymentMethod: null,
  spentBonusCount: 0,
  bonusCardNumber: '',
  collectEmailForReceipt: false
}

type Action = {
  type: "CHANGE_PAYMENT_LOADING"
  payload: boolean
} | {
  type: "CHANGE_PAYMENT_CARD_LOADING"
  payload: boolean
} | {
  type: "CHANGE_PAYMENT_WAITING"
  payload: boolean
} | {
  type: "CHANGE_SUCCESS_FINISH_PAYMENT"
  payload: boolean
} | {
  type: "CHANGE_DISPLAY_PAYMENT"
  payload: boolean
} | {
  type: "CHANGE_CONFIRM_CLOSED_PAYMENT_MODAL"
  payload: boolean
} | {
  type: "SET_PAYMENT_ERROR"
  payload: ERRORS | null,
} | {
  type: "SET_PAYMENT_ERROR_MESSAGE"
  payload: string,
} | {
  type: "SET_PAYMENT_STEP"
  payload: EPaymentSteps,
} | {
  type: "SET_SELECTED_PAYMENT_METHOD"
  payload: EPaymentMethods | EBonusSystemType | null,
} | {
  type: "SET_ORDER_DATA"
  payload: any
}

function paymentReducer(state: PaymentState, action: Action) {
  switch (action.type) {
    case "CHANGE_PAYMENT_LOADING": {
      return {
        ...state,
        isPaymentLoading: action?.payload,
      };
    }

    case "CHANGE_PAYMENT_CARD_LOADING": {
      return {
        ...state,
        isPaymentCardLoading: action?.payload,
      };
    }

    case "CHANGE_PAYMENT_WAITING": {
      return {
        ...state,
        isPaymentWaiting: action?.payload,
      };
    }

    case "CHANGE_SUCCESS_FINISH_PAYMENT": {
      return {
        ...state,
        isSuccessPayment: action?.payload,
      };
    }

    case "CHANGE_DISPLAY_PAYMENT": {
      return {
        ...state,
        displayPayment: action?.payload,
      };
    }

    case "CHANGE_CONFIRM_CLOSED_PAYMENT_MODAL": {
      return {
        ...state,
        confirmClosedPaymentModal: action?.payload,
      };
    }

    case "SET_PAYMENT_ERROR": {
      return {
        ...state,
        paymentError: action?.payload,
      };
    }

    case "SET_PAYMENT_ERROR_MESSAGE": {
      return {
        ...state,
        paymentErrorMessage: action?.payload,
      };
    }

    case "SET_PAYMENT_STEP": {
      return {
        ...state,
        paymentStep: action?.payload,
      };
    }

    case "SET_SELECTED_PAYMENT_METHOD": {
      return {
        ...state,
        selectedPaymentMethod: action?.payload,
      };
    }

    case "SET_ORDER_DATA": {
      return {
        ...state,
        orderData: action?.payload,
      };
    }
  }
}


export const PaymentContext = React.createContext<PaymentState>(initialStorePaymentState);

let GLOBAL_TIME: number = 0

export const PaymentProvider: FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = React.useReducer(paymentReducer, initialStorePaymentState);

  const {settings} = useGlobalSettings()
  const {extraForOrderProducts} = useCatalogData();
  const {cart, products, selectedPromoCode, cartId} = useCartContext();

  const {sendingEmailError, resetEmailRequestData, handleEmailSend} = useSendEmail()
  const { handleValidateBonusCard, spentBonusCount, setSpentBonusCount, bonusCard, bonusCardNumber,
    setBonusCardNumber, isErrorBonusCard, isCheckingBonusCardNumber, setIsErrorBonusCard, handleBonusCardClear} = useBonusCard()

  // useEffect(() => {
  //   if (extraForOrderProducts?.length) {
  //     setPaymentStep(EPaymentSteps.ADDITIONAL_PRODUCT)
  //   }
  // }, [extraForOrderProducts])

  const setIsPaymentCardLoading = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_PAYMENT_CARD_LOADING", payload: value}), [dispatch]);

  const setIsPaymentLoading = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_PAYMENT_LOADING", payload: value}), [dispatch]);

  const setIsPaymentWaiting = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_PAYMENT_WAITING", payload: value}), [dispatch]);

  const setIsSuccessPaymentFinish = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_SUCCESS_FINISH_PAYMENT", payload: value}), [dispatch]);

  const setDisplayPayment = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_DISPLAY_PAYMENT", payload: value}), [dispatch]);

  const setConfirmClosedPaymentModal = useCallback(
    (value: boolean) => dispatch({type: "CHANGE_CONFIRM_CLOSED_PAYMENT_MODAL", payload: value}), [dispatch]);

  const setPaymentError = useCallback(
    (value: ERRORS | null) => dispatch({type: "SET_PAYMENT_ERROR", payload: value}), [dispatch]);

  const setPaymentErrorMessage = useCallback(
    (value: string) => dispatch({type: "SET_PAYMENT_ERROR_MESSAGE", payload: value}), [dispatch]);

  const setPaymentStep = useCallback(
    (value: EPaymentSteps) => dispatch({type: "SET_PAYMENT_STEP", payload: value}), [dispatch]);

  const setSelectedPaymentMethod = useCallback(
    (value: EPaymentMethods | EBonusSystemType | null,) => dispatch({type: "SET_SELECTED_PAYMENT_METHOD", payload: value}), [dispatch]);

  const setOrderData = useCallback(
    (value: any) => dispatch({type: "SET_ORDER_DATA", payload: value}), [dispatch]);

  const onClearBonusCard = () => {
    handleBonusCardClear()
  }

  const handleCloseDisplayPayment = () => {
    handlePaymentContextClear()
    setDisplayPayment(false)
  }

  const handlePaymentContextClear = () => {
    GLOBAL_TIME = 0
    onClearBonusCard()
    resetEmailRequestData()
    _startsPaymentAgain()
    setPaymentStep(EPaymentSteps.PAYMENT_METHODS)
    setSelectedPaymentMethod(null)
    setBonusCardNumber('')
    setIsErrorBonusCard(false)
  }

  const _startsPaymentAgain = () => {
    GLOBAL_TIME = 0
    setOrderData(null)
    setIsPaymentWaiting(false)
    setIsPaymentCardLoading(false)
    setIsSuccessPaymentFinish(false)
    setIsPaymentLoading(false)
    setPaymentError(null)
    setPaymentErrorMessage('')
    setConfirmClosedPaymentModal(false)
  }

  const _changePaymentStep = (step: EPaymentSteps) => {
    setPaymentStep(step)
  }

  const handleErrorRequest = (error: HttpError | null, timestamp?: string) => {

    setIsPaymentLoading(false)
    setIsPaymentCardLoading(false)

    if (state.paymentStep === EPaymentSteps.FINISH_PAYMENT && (timestamp === `${GLOBAL_TIME}`)) {

      onClearBonusCard()

      if (error?.error?.code === ERRORS?.TERMINAL_TIMEOUT) {
        setPaymentError(ERRORS.TERMINAL_TIMEOUT)
        return;
      }

      if (settings?.sounds?.paymentFailure?.enabled) {
        makeSound(settings?.sounds?.paymentFailure?.fileUrl || '')
      }

      if (error instanceof DOMException) {
        if (error?.code === 20) {
          setPaymentError(ERRORS.TIMEOUT_REQUEST_ERROR)
        }
        return;
      }

      if (ERROR_CODES[error?.error?.code] === ERRORS.YANDEX_PAYMENT_FAIL) {

        if (error?.error?.message) {
          setPaymentError(error?.error?.message)
          setPaymentErrorMessage(error?.error?.message)
          return;
        }

        if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE) {
          setPaymentError(ERRORS.YANDEX_BADGE_PAYMENT_FAIL)
          return;
        }

        if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA) {
          setPaymentError(ERRORS.YANDEX_EDA_PAYMENT_FAIL)
          return;
        }

        if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_QR) {
          setPaymentError(ERRORS.YANDEX_BADGE_PAYMENT_FAIL)
          return;
        }
      }

      if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_ON_LUNCH && ERROR_CODES[error?.error?.code] === ERRORS.NA_LUNCH_PAYMENT_FAIL) {
        setPaymentError(ERRORS.NA_LUNCH_PAYMENT_FAIL)
      }

      if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD) {
        setPaymentError(ERRORS.CARD_NOT_WORK)
      }

      setPaymentError(ERRORS.SOMETHING_WENT_WRONG)
    }
  }

  const handlePaymentMake = async () => {
    if (state.isPaymentCardLoading) {
      return;
    }
    console.log('its running card payment')

    let paymentType: any;
    setIsPaymentLoading(true)
    setIsPaymentCardLoading(true)
    setPaymentErrorMessage('')
    setPaymentError(null)

    if (
      !!bonusCardNumber && (
        settings?.bonusSystemType === EBonusSystemType.OBEDRU || settings?.bonusSystemType === EBonusSystemType.OZON
      )
    ) {
      paymentType = 'OBED.RU';
    } else {
      paymentType = !state.selectedPaymentMethod ? 'Card' : {
        [EPaymentMethods.PAYMENT_TYPE_CARD]: 'Card',
        [EPaymentMethods.PAYMENT_TYPE_SBP]: 'sbp',
        [EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE]: 'Badge',
        [EPaymentMethods.PAYMENT_TYPE_YANDEX_QR]: 'YandexQr',
        [EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA]: 'YandexEda',
        [EPaymentMethods.PAYMENT_TYPE_ON_LUNCH]: 'Nalunch',
        [EBonusSystemType.GOPOEDIM]: 'Card',
        [EBonusSystemType.OZON]: 'Card',
        [EBonusSystemType.OBEDRU]: 'Card',
      }[state.selectedPaymentMethod];
    }

    GLOBAL_TIME = Math.random()
    let localTime = `${GLOBAL_TIME}`.slice()

    try {
      await fetch(urlJoin(API_URL, `/payment/card_pay/`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // @ts-ignore
        body: JSON.stringify(formatterPaymentBody({
          products,
          paymentType,
          selectedPaymentMethod: state.selectedPaymentMethod,
          selectedPromoCode,
          bonusCard,
          cart,
          spentBonusCount: spentBonusCount || 0,
          cartId
        }))})
        .then(async (response) => {
          const result = await response?.json()
          if (!response.ok) {
            throw result;
          }
          setOrderData(result?.data)
          setIsPaymentCardLoading(false)
          setIsPaymentLoading(false)
          // @ts-ignore
          if (state.selectedPaymentMethod && [EPaymentMethods.PAYMENT_TYPE_SBP, EPaymentMethods.PAYMENT_TYPE_ON_LUNCH].includes(state.selectedPaymentMethod)) {
            await handlePaymentWait(result?.data?.order_id || '')
          } else {
            setIsSuccessPaymentFinish(true)
            setPaymentStep(EPaymentSteps.PAYMENT_METHODS)
          }
          if (state.selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE) {
            saveYBadgeLastPaymentUnixTime();
          }
          if (`${GLOBAL_TIME}` === localTime) {
            onClearBonusCard()
          }
        })
        .catch((error) => {
          handleErrorRequest(error, localTime)
        })
    } catch (e) {
      handleErrorRequest(null, localTime)
    }
  }

  const handleYandexPaymentMake = async (paymentToken = '') => {
    if (state.isPaymentLoading) {
      return;
    }
    console.log('its running yandex payment')
    let paymentType = state.selectedPaymentMethod;
    setPaymentErrorMessage('')
    setPaymentError(null)
    if (!paymentType) {
      return;
    }
    setIsPaymentLoading(true)
    GLOBAL_TIME = Math.random()
    let localTime = `${GLOBAL_TIME}`.slice()

    try {
      await fetch(urlJoin(API_URL, `/payments/yandex_pay/`), {
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=utf-8'},
        // @ts-ignore
        body: JSON.stringify(formatterYPaymentBody({
          products, paymentType, paymentToken, cart
        })),
      })
        .then(async (response) => {
          const result = await response?.json()
          if (!response.ok) {
            throw result;
          }
          setIsPaymentLoading(false)
          setOrderData(result?.data)
          await handlePaymentMake()
        })
        .catch((error) => {
          if (paymentType === EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE) {
            saveYBadgeLastPaymentUnixTime();
          }
          handleErrorRequest(error, localTime)
        })
    } catch (e) {
      handleErrorRequest(null, localTime)
    }
  }

  const handlePaymentWait = async (orderId: string) => {
    if (!orderId) {
      return;
    }
    console.log('its running qr-code (sbp, nalunch) payment')

    setIsPaymentWaiting(true)
    // @ts-ignore
    const timeout = state.selectedPaymentMethod ? {
      [EPaymentMethods.PAYMENT_TYPE_SBP]: settings?.sbpPaymentTimeout,
      [EPaymentMethods.PAYMENT_TYPE_ON_LUNCH]: settings?.nalunchPaymentTimeout,
    }[state.selectedPaymentMethod as EPaymentMethods] || ABORT_PAYMENT_TIME_SEC : ABORT_PAYMENT_TIME_SEC;

    const abortCtrl = new AbortController();

    GLOBAL_TIME = Math.random()
    let localTime = `${GLOBAL_TIME}`.slice()

    setTimeout(() => {
      abortCtrl.abort()
    }, timeout * 1000);

    const qrTypes: {[key: string]: string} = {
      [EPaymentMethods.PAYMENT_TYPE_SBP]: '/payment/get_sbp_payment_status/',
      [EPaymentMethods.PAYMENT_TYPE_ON_LUNCH]: '/payment/get_nalunch_payment_status/',
    }
    const apiPath: string = state.selectedPaymentMethod ? qrTypes[state.selectedPaymentMethod] || '' : '';

    try {
      await fetch(
        urlJoin(API_URL, apiPath), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // @ts-ignore
          body: JSON.stringify({
            order_id: orderId,
          }),
          signal: abortCtrl.signal,
        },
      ).then(async (response) => {
          const result = await response?.json()
          if (!response.ok) {
            throw result;
          }
          setIsSuccessPaymentFinish(true)
          setIsPaymentWaiting(false)
          setPaymentStep(EPaymentSteps.PAYMENT_METHODS)
        })
        .catch((error) => {
          if ((localTime === `${GLOBAL_TIME}`)) {
            setIsPaymentWaiting(false)
          }
          handleErrorRequest(error, localTime)
        })
    } catch (e) {
      handleErrorRequest(null, localTime)
    }
  }

  const _openDisplayPayment = (payNow: boolean) => {
    if (settings?.payWithBonusPointsOnly && bonusCard?.userId && state.paymentStep === EPaymentSteps.PAYMENT_METHODS) {
      if (payNow) {
        _changePaymentStep(EPaymentSteps?.FINISH_PAYMENT)
      } else {
        _changePaymentStep(EPaymentSteps?.WRITE_OFF_BONUSES)
      }
    }
    setDisplayPayment(true)
  }

  return <PaymentContext.Provider value={{
    ...state,
    bonusCard,
    bonusCardNumber,
    spentBonusCount,
    isErrorBonusCard,
    isCheckingBonusCardNumber,
    isPaymentProcess: state.isPaymentWaiting || state.isPaymentCardLoading || state.isPaymentLoading,
    sendingEmailError: sendingEmailError,

    // @ts-ignore
    onClearPaymentContext: handlePaymentContextClear,
    onStartPaymentAgain: _startsPaymentAgain,
    onOpenDisplayPayment: _openDisplayPayment,
    onCloseDisplayPayment: handleCloseDisplayPayment,
    onConfirmClosedPaymentModal: () => setConfirmClosedPaymentModal(true),
    onCancelClosedPaymentModal: () => setConfirmClosedPaymentModal(false),
    onChangePaymentStep: _changePaymentStep,
    onChangeBonusCardNumber: (value: string) => setBonusCardNumber(value),
    onValidateBonusCard: handleValidateBonusCard,
    onChangeSpentBonusCount: (value: number) => setSpentBonusCount(value),
    onClearBonusCard: onClearBonusCard,
    onSelectPaymentMethod: (method: EPaymentMethods | EBonusSystemType | null) => setSelectedPaymentMethod(method),
    onMakePayment: handlePaymentMake,
    onMakeYandexPayment: handleYandexPaymentMake,
    onSendEmail: handleEmailSend
  }} {...props}/>;
};

export const usePayment = (): PaymentMethodsContext => {
  const context = React.useContext(PaymentContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context as PaymentMethodsContext;
};

export const ManagedPaymentContext: FC<PropsWithChildren> = ({children}) => (
  <PaymentProvider>
    {children}
  </PaymentProvider>
);
